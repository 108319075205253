@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;400;500&display=swap");
@font-face {
  font-family: "Avenir";
  src: local("Avenir-Light"),
    url("../public/fonts/avenir/AvenirArabic-Light.otf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Avenir";
  src: local("Avenir-Normal"),
    url("../public/fonts/avenir/AvenirArabic-Book.otf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Avenir";
  src: local("Avenir-Bold"),
    url("../public/fonts/avenir/AvenirArabic-Heavy.otf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Avenir";
  src: local("Avenir-Extrabold"),
    url("../public/fonts/avenir/AvenirArabic-Black.otf") format("truetype");
  font-weight: 700;
}
:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-gold: #c59d5f;

  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Avenir", sans-serif !important;
  background: var(--clr-grey-10);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
  @media (min-width: 1025px) {
    max-width: 1025px;
    width: 1025px;
    min-width: 600px;
    margin: auto !important;
    background-color: #ddd;
  }
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5 {
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
.truncate {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
/*  global classes */

/* section */
.section {
  width: 100%;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section {
    width: 100%;
  }
}

/*
=============== 
HEADER
===============
*/
.bg-video {
  width: 100%;
}

/*
=============== 
Menu
===============
*/

.menu {
  padding: 0;
}
.title {
  font-size: 17px;
  font-weight: 600;
  margin-top: 5px;
}
.underline {
  width: 5rem;
  height: 0.25rem;
  background: var(--clr-gold);
  margin-left: auto;
  margin-right: auto;
}
.btn-container {
  width: 100%;
  /* 
  overflow: scroll;
  direction: ltr;
  */
  display: flex;
  height: 50px;
  align-items: center;
  flex-shrink: 0;
  padding: 0px 15px 0px 0px;
}
.filter-btn {
  background: transparent;
  border-color: transparent;
  font-size: 14px;
  text-transform: uppercase;
  margin: 10px 0 10px 10px;
  letter-spacing: 1px;
  padding: 5px 15px;
  color: var(--clr-gold);
  cursor: pointer;
  transition: var(--transition);
  min-width: 120px;
  background: transparent;
  min-width: auto;
  border-radius: 30px;
  border: 1px solid;
  width: auto;
  max-width: max-content;
  min-inline-size: max-content;
  text-decoration: none;
  height: 35px;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.filter-btn.active {
  background: var(--clr-gold);
  color: var(--clr-white);
}
.section-center {
  width: 100%;
  margin: 0 auto;
  justify-items: center;
}
.menu-list-item {
  background: #fff;
  cursor: pointer;
}
.menu-list-item:hover {
  background: #f5f5f5;
}
.menu-item {
  display: grid;
  gap: 1rem 2rem;
  max-width: 25rem;
}
.photo {
  object-fit: cover;
  height: 150px;
  width: 150px;
  border-radius: 10px;
  border: 1px solid lightgray;
}
.item-info header {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px dotted var(--clr-grey-5);
}
.item-info h4 {
  margin-bottom: 0.5rem;
}
.price {
  color: #4f7a28;
  font-size: 15px;
  font-weight: 700;
}
.item-text {
  margin-bottom: 0;
}

.item-desc {
  line-height: 23px;
  font-size: 15px;
  text-transform: capitalize;
  color: #6c757d;
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 12px;
}

@media screen and (min-width: 768px) {
  .menu-item {
    /*grid-template-columns: 225px 1fr;
    max-width: 100%;*/
    gap: 0 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
}

body {
  background-color: #000000;
}

.padding {
  padding: 3rem !important;
  margin-left: 200px;
}

.card-img-top {
  height: 300px;
}

.card-no-border .card {
  border-color: #d7dfe3;
  border-radius: 4px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.pro-img {
  margin-top: -65px;
  margin-bottom: 20px;
}
.menu-detail {
  position: relative;
}
.menu-detail .pro-img img {
  width: 100px;
  height: 100px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
}

html body .m-b-0 {
  margin-bottom: 0px;
}

h3 {
  line-height: 30px;
  font-size: 21px;
}

.btn-rounded.btn-md {
  padding: 12px 35px;
  font-size: 16px;
}

html body .m-t-10 {
  margin-top: 10px;
}

.btn-primary,
.btn-primary.disabled {
  background: #7460ee;
  border: 1px solid #7460ee;
  -webkit-box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14),
    0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
  box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14),
    0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-rounded {
  border-radius: 60px;
  padding: 7px 18px;
}

.m-t-20 {
  margin-top: 20px;
}

.text-center {
  text-align: center !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #455a64;
  /* font-family: "Poppins", sans-serif; */
  font-weight: 400;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.menu-detail h6 {
  font-weight: normal;
  font-size: 14px;
}
.categories-slide {
  /* width: 100%;
    display: inline-flex;
    background-color: #fff;
    padding: 5px 0px;

    overflow-x: auto; overflow-y: hidden;  display: flex;  white-space: nowrap;*/

  background-color: #fff;
  padding: 5px 0px;
}

.menu-card {
  border-radius: 0% 0% 50% 50% / 85% 85% 15% 15% !important;
  overflow: hidden;
  height: 270px !important;
  position: relative;
  z-index: -1;
}

.curved-corner::before {
  content: "";
  display: block;
  width: 250%;
  height: 80%;
  position: absolute;
  border-radius: 26%;
  bottom: 0;
  left: 0;
  box-shadow: 0px 70px 0px 58px white;
}

.curved-corner::after {
  content: "";
  display: block;
  width: 250%;
  height: 80%;
  position: absolute;
  border-radius: 23%;
  bottom: 0;
  box-shadow: 0px 70px 0px 58px white;
  right: 0;
}

.sticky-category-nav {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  align-self: flex-start;
  height: auto;
}

.section-title {
  font-size: 18px;
  padding: 65px 15px 4px 15px;
  margin-top: -35px;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
}

.section-title.all,
.filter-btn.all {
  display: none;
}

button.close-btn {
  border: none;
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
  margin: 0;
  padding: 5px 10px 10px 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  font-size: 30px;
  color: #0e0000;
  text-align: center;
  position: absolute;
  top: 20px;
  right: 20px;
}
button.close-btn:hover {
  background: #eeeeee;
}

.ReactModal__Content {
  inset: 0 !important;
  padding: 0px !important;
}

#preloader {
  position: fixed;
  z-index: 99999999999;
  background: #fff;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: green;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.lang-btn {
  position: absolute;
  z-index: 0;
  background-color: #ffffff96;
  border: 0;
  padding: 6px 15px;
  border-radius: 21px;
  margin: auto;
  display: block;
  left: 0;
  right: 0;
  width: -moz-fit-content;
  width: fit-content;
  top: 20px;
  color: #000;
  font-size: 14px;
  text-transform: uppercase;
}

.social-icons {
  margin: 10px auto;
  text-align: center;
  padding: 10px 0px 0px 0px;
  border-top: 1px solid #ddd;
}

.social-icons li {
  display: inline-block;
  margin: 5px;
}

.social-icons li a {
  text-decoration: none;
}
